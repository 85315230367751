import React, { useState } from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { DocumentPreview } from '@enotarylog/pdftron-webviewer/viewer/components/DocumentPreview';
import { DialogContent, DialogActions } from 'document-viewer/src/components/Dialog';
import { isMobileDevice } from 'document-viewer/src/utils/agents';
import { useStyles } from './styles';
import { NoVerificationProps } from '../types';
import TermsAndConditions from './Terms';

export default function NoVerification({
  onComplete,
  name
}: NoVerificationProps) {
  const classes = useStyles({});

  const [checkedTermsConditionPrivacyPolicy, setTermsConditionPrivacyPolicyChecked] = useState(false);
  const [checkedeSignConsent, setSignConsentChecked] = useState(false);

  const isMobile = isMobileDevice();
  const submitDisabled = !checkedTermsConditionPrivacyPolicy || !checkedeSignConsent;
  const CDN_URL = process.env.CDN_URL
  return (
    <>
      <DialogContent
        className={classes.verticallySpaced}
      >
        <Typography>
          {
            name ? (
              <>
                <strong>{name}</strong>
                , in
              </>
            ) : 'In'
          }
          {' '}
          order to continue, you must first accept our Terms and Conditions, Privacy Policy, and eConsent.

        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          flexDirection='column'
          mb={3}
        >
          <TermsAndConditions
            termsAndPrivacyPolicy={checkedTermsConditionPrivacyPolicy}
            setTermsAndPrivacyPolicy={setTermsConditionPrivacyPolicyChecked}
            signConsent={checkedeSignConsent}
            setSignConsent={setSignConsentChecked}
          />
        </Box>

        {!isMobile && (
          <div
            style={{
              width: '100%',
              height: '350px',
            }}
          >
            <DocumentPreview url={CDN_URL+"/enl-public-files/static/pdf/esign_policy.pdf" }/>
          </div>
        )}
      </DialogContent>

      <DialogActions className={clsx(classes.dialogActions, classes.noPaddingTop)}>
        <Button
          color='primary'
          disabled={submitDisabled}
          onClick={onComplete}
          variant='contained'
          className={clsx(classes.button, classes.marginTopSm)}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
}
