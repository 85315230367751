import { ApolloClient } from '@apollo/client';
import { isObject, isEmpty } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultData, defaultTheme } from '../constants';
import { GET_IFRAME_REQUEST, GET_IFRAME_REQUEST_WITH_EQUIPMENT_CHECK_URL } from 'document-viewer/src/lib/gql/iframe-request';
import { AppThunk, RootState } from 'document-viewer/src/store';
import { RequestData } from 'document-viewer/src/utils/types/request-data';
import { setTheme } from './theme';
import * as R from '@enotarylog/ramda';



export interface RequestDataState {
  loading: boolean
  data: RequestData
}

export const initialState: RequestDataState = {
  loading: false,
  data: {}
};

const slice = createSlice({
  name: 'requestData',
  initialState,
  reducers: {
    setLoading(state: RequestDataState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setData(state: RequestDataState, action: PayloadAction<{ data: RequestData }>) {
      state.data = {
        ...action.payload.data,
      };
    },
  },
});

export const { setData } = slice.actions;

export const getIframeRequest = (id: string, eqCheck): AppThunk => async (dispatch, __, client: ApolloClient<any>) => {
  const query = !eqCheck ? GET_IFRAME_REQUEST : GET_IFRAME_REQUEST_WITH_EQUIPMENT_CHECK_URL;

  dispatch(slice.actions.setLoading(true));

  if (id) {
    const { data } = await client.query({
      query,
      variables: { id }
    });

    dispatch(slice.actions.setData({
      data: {
        ...defaultData,
        ...data.getIframeRequest?.data,
        equipmentCheckUrl: data.getIframeRequest?.equipmentCheckUrl
      }
    }));

    const iframeRequestStyle = R.path(['getIframeRequest', 'style'], data);

    /*
      Apply iframeRequest style ONLY if it contains a VALID value.
      If iframeRequest's style data is null, PREVENT "defaultTheme" from overriding
      organization setting's styles without a valid reason.
    */
    if (isObject(iframeRequestStyle) && !isEmpty(iframeRequestStyle)) {
      dispatch(setTheme(R.mergeDeepRight(defaultTheme, iframeRequestStyle)));
    }

    dispatch(slice.actions.setLoading(false));
  } else {
    dispatch(setTheme(defaultTheme));
    dispatch(slice.actions.setLoading(false));
  }
};

export const reducer = slice.reducer;
export const selector = (state: RootState): RequestDataState => state.requestData
export default slice;

