import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useStyles } from './styles';
import { useCallback } from 'react';

const TermsAndConditions = ({
  termsAndPrivacyPolicy,
  setTermsAndPrivacyPolicy,
  signConsent,
  setSignConsent,
}) => {
  const CDN_URL = process.env.CDN_URL
  const classes = useStyles({});
  const allVisibleStyle = { marginTop: '8px' };

  const handleTermsAndPrivacyPolicyUpdate = useCallback((checked: boolean) => {
    setTermsAndPrivacyPolicy(checked);
  }, []);

  const handleSignConsentUpdate = useCallback((checked: boolean) => {
    setSignConsent(checked);
  }, []);
  
  return (
    <>
      <FormGroup className={classes.marginTopSm}>
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <div className={classes.checkboxContainer}>
              <Checkbox
                checked={termsAndPrivacyPolicy}
                onChange={(evt) => { handleTermsAndPrivacyPolicyUpdate(evt.target.checked);}}
              />
            </div>
          }
          label={
            <>
              By clicking the checkbox, you agree to eNotaryLog's
              {' '}
              <a
                href={CDN_URL+"/enl-public-files/static/pdf/tc.pdf"}
                target='_blank'
                rel='noreferrer noopener'
              >
                Terms and Conditions
              </a>
              {' '}
              and
              {' '}
              <a
                href={CDN_URL+"/enl-public-files/static/pdf/privacy.pdf"}
                target='_blank'
                rel='noreferrer noopener'
              >
                Privacy Policy
              </a>
            </>
          }
        />
      </FormGroup>

      <FormGroup className={classes.marginTopSm}>
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <div className={classes.checkboxContainer}>
              <Checkbox
                checked={signConsent}
                onChange={(evt) => { handleSignConsentUpdate(evt.target.checked) }}
              />
            </div>
          }
          label={
            <>
              By clicking the checkbox, you agree to eNotaryLog's
              {' '}
              <a
                href={CDN_URL+"/enl-public-files/static/pdf/esign_policy.pdf"}
                target='_blank'
                rel='noreferrer noopener'
              >
                eConsent to use Electronic Signatures, Records and Communications.
              </a>
            </>
          }
        />
      </FormGroup>
    </>
  );
};

export default TermsAndConditions;
